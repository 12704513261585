import {
  CHANGE_ADMIN_ROUTE_REQUEST,
  CHANGE_ADMIN_STATUS_REQUEST,
  CHANGE_ROUTE_REQUEST,
  REQUEST_ACTIVE_BLOG_FAILED,
  REQUEST_ACTIVE_BLOG_PENDING,
  REQUEST_ACTIVE_BLOG_SUCCESS,
  REQUEST_BLOGS_FAILED,
  REQUEST_BLOGS_PENDING,
  REQUEST_BLOGS_SUCCESS,
  REQUEST_EDIT_BLOG_FAILED,
  REQUEST_EDIT_BLOG_PENDING,
  REQUEST_EDIT_BLOG_SUCCESS,
  REQUEST_NAVBAR_STATUS,
  RESET_EDIT_BLOG,
} from "../Constants/constants";

export const requestRoute = (route) => ({
  type: CHANGE_ROUTE_REQUEST,
  payload: route,
});

const serverType = process.env.REACT_APP_SERVER_TYPE;
const devServer = process.env.REACT_APP_DEVELOPMENT_SERVER;
const prodServer = process.env.REACT_APP_PRODUCTION_SERVER;

// const server = serverType === "development" ? devServer : prodServer;

// const server = devServer;
const server = prodServer;

export const requestBlogsAction = (skip) => (dispatch) => {
  // console.log("server", server);
  dispatch({ type: REQUEST_BLOGS_PENDING });
  fetch(`${server}/getBlogs?skip=${skip}`)
    .then((res) => res.json())
    .then((blogs) => dispatch({ type: REQUEST_BLOGS_SUCCESS, payload: blogs }))
    .catch((err) => dispatch({ type: REQUEST_BLOGS_FAILED, payload: err }));
};

export const requestActiveBlogAction = (blogId) => (dispatch) => {
  if (!blogId) {
    dispatch({ type: RESET_EDIT_BLOG });
  } else {
    dispatch({ type: REQUEST_ACTIVE_BLOG_PENDING });
    fetch(`${server}/post/${blogId}`)
      .then((res) => res.json())
      .then((data) =>
        dispatch({ type: REQUEST_ACTIVE_BLOG_SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: REQUEST_ACTIVE_BLOG_FAILED, payload: error })
      );
  }
};

export const requestAdminAction = () => ({
  type: CHANGE_ADMIN_STATUS_REQUEST,
});

export const requestAdminRouteAction = (route) => ({
  type: CHANGE_ADMIN_ROUTE_REQUEST,
  payload: route,
});

export const requestEditBlogAction = (blogId) => (dispatch) => {
  if (!blogId.length) {
    dispatch({ type: RESET_EDIT_BLOG });
  } else {
    dispatch({ type: REQUEST_EDIT_BLOG_PENDING });
    fetch(`${server}/post/${blogId}`)
      .then((res) => res.json())
      .then((data) =>
        dispatch({ type: REQUEST_EDIT_BLOG_SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: REQUEST_EDIT_BLOG_FAILED, payload: error })
      );
  }
};

export const requestNavAction = () => ({
  type: REQUEST_NAVBAR_STATUS,
});
