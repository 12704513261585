import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
// import TopBar from "../../components/Topbar/Topbar";
// import { Route, Routes } from "react-router-dom";
// import Forum from "../../routes/Forum/forum";
// import Home from "../../routes/Home/home";
// import BlogDetails from "../components/Blog-Details/blofDetails";
// import "./App.scss";
import LoadingIcon from "../../components/Loading-Spinner/loadingIcon";
import "./guestUser.scss";

const Forum = lazy(() => import("../../routes/Forum/forum"));
const Home = lazy(() => import("../../routes/Home/home"));
const AboutUs = lazy(() => import("../../routes/About-us/aboutUs"));
const ContactUs = lazy(() => import("../../routes/ContactUs/contact-us"));
const Policy = lazy(() => import("../../routes/Privacy-policy/privacy"));
// const BlogDeatils = lazy(() =>
//   import("../../components/Blog-Details/blogDetails.jsx")
// );

const GuestUser = () => {
  // const dispatch = useDispatch();
  const { currentRoute } = useSelector((state) => state.setRoute);
  const onRouteChange = () => {
    switch (currentRoute) {
      case "home":
        return (
          <Suspense
            fallback={
              <div className="margin-top">
                <LoadingIcon />
              </div>
            }
          >
            <Home />
          </Suspense>
        );
      case "forum":
        return (
          <Suspense
            fallback={
              <div className="margin-top">
                <LoadingIcon />
              </div>
            }
          >
            <Forum />
          </Suspense>
        );
      case "info":
        return (
          <Suspense
            fallback={
              <div className="margin-top">
                <LoadingIcon />
              </div>
            }
          >
            <AboutUs />
          </Suspense>
        );
      case "contactus":
        return (
          <Suspense
            fallback={
              <div className="margin-top">
                <LoadingIcon />
              </div>
            }
          >
            <ContactUs />
          </Suspense>
        );
      case "policy":
        return (
          <Suspense
            fallback={
              <div className="margin-top">
                <LoadingIcon />
              </div>
            }
          >
            <Policy />
          </Suspense>
        );
      default:
        return;
    }
  };
  useEffect(() => {
    onRouteChange();
  }, []);
  return (
    <div className="main-container">
      {/* <Navbar /> */}
      <div className="main-body">{onRouteChange()}</div>
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/forum" element={<Forum />} />

        <Route
          path="/post"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogDeatils />
            </Suspense>
          }
        />
      </Routes> */}
    </div>
  );
};

export default GuestUser;
