import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestActiveBlogAction } from "../../Actions/actions";
import LoadingIcon from "../Loading-Spinner/loadingIcon";
import "./blogDetails.scss";

const BlogContent = lazy(() => import("../BlogContent/blogContent"));
const GoogleAds = lazy(() => import("../GoogleAds/googleAds"));

const BlogDetails = () => {
  const dispatch = useDispatch();
  const loadBlog = () => {
    dispatch(requestActiveBlogAction(id));
  };
  const { ispending, blog, error } = useSelector(
    (state) => state.requestActiveBlogReducer
  );
  const [keywords, setKeywords] = useState("");
  const { id } = useParams();
  // console.log(id);

  const setMetaImageTag = (titleImage) => {
    // Base64 encoded image string
    const base64Image = titleImage;

    // Decode the Base64 string into binary data
    const binaryData = atob(base64Image);

    // Convert the binary data into a Blob object
    const blob = new Blob([binaryData], { type: "image/webp" });

    // Create an object URL for the Blob
    const imageUrl =
      "https://awwrites.com/?image=" + encodeURIComponent(base64Image);

    // Create a <meta> tag dynamically
    const metaTag = document.createElement("meta");

    // Set the og:image property of the <meta> tag to the object URL
    metaTag.setAttribute("property", "og:image");
    metaTag.setAttribute("itemprop", "image");
    metaTag.setAttribute("content", imageUrl);

    // Append the <meta> tag to the <head> element
    document.head.appendChild(metaTag);
  };
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!blog.length) {
      loadBlog();
    }
    if (blog.title) {
      const existingString = blog.title;
      const wordsArray = existingString.match(/\w+/g);
      const newString = wordsArray.join(", ");
      setKeywords(newString);
    }
    // console.log(blog, error);
  }, []);

  useEffect(() => {
    if (blog.titleImage) {
      setMetaImageTag(blog.titleImage);
    }
  }, [blog.titleImage]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/post/${blog.id}`,
      title: `${blog.title}`,
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{blog.title}</title>
        <link rel="canonical" href={`https://awwrites.com/post/${blog.id}`} />
        <link rel="prerender" href={`https://awwrites.com/post/${blog.id}`} />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={blog.description ? blog.description : blog.title}
        />
        {/* No need of this tag as meta image will be automatically added */}
        {/* <meta
            property="og:image"
            itemprop="image"
            content={`data:image/webp;base64,${blog.titleImage}`}
            data-rh="true"
          /> */}
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="article" />
        <meta property="og:updated_time" content={blog.createdOn} />
        <meta
          name="keywords"
          content={blog.keywords ? blog.keywords.join(", ") : keywords}
        />
        <meta
          property="og:url"
          content={`https://awwrites.com/post/${blog.id}`}
        />
        <meta name="twitter:title" content={blog.title} />
        <meta
          name="twitter:description"
          content={blog.description ? blog.description : blog.title}
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9847191225953947"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="blog-content-container">
        <Suspense fallback={<LoadingIcon />}>
          <BlogContent blog={blog} key={blog.id} />
          <GoogleAds />
        </Suspense>
      </div>
    </HelmetProvider>
  );
};

export default BlogDetails;
