import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  requestActiveBlogAction,
  requestAdminRouteAction,
  requestBlogsAction,
} from "../../Actions/actions";
import "./postThumbnail.scss";

const PostThumbnail = ({ blog }) => {
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state) => state.requestAdminReducer);
  const end = DateTime.now();
  const start = DateTime.fromISO(blog.createdOn); //We have to put .toISOString() with new Date()
  const server = process.env.REACT_APP_PRODUCTION_SERVER;

  const diff = end
    .diff(start, [
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
    ])
    .shiftTo("years", "months", "weeks", "days", "hours", "minutes", "seconds");

  let difference = "";

  if (diff.years > 0) {
    difference = `${diff.years} ${diff.years === 1 ? "year" : "years"} ago`;
  } else if (diff.months > 0) {
    difference = `${diff.months} ${diff.months === 1 ? "month" : "months"} ago`;
  } else if (diff.weeks > 0) {
    difference = `${diff.weeks} ${diff.weeks === 1 ? "week" : "weeks"} ago`;
  } else if (diff.days > 0) {
    difference = `${diff.days} ${diff.days === 1 ? "day" : "days"} ago`;
  } else if (diff.hours > 0) {
    difference = `${diff.hours} ${diff.hours === 1 ? "hour" : "hours"} ago`;
  } else if (diff.minutes > 0) {
    difference = `${diff.minutes} ${
      diff.minutes === 1 ? "minute" : "minutes"
    } ago`;
  } else {
    difference = `${diff.seconds} ${
      diff.seconds === 1 ? "second" : "seconds"
    } ago`;
  }

  const getNumber = (num) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(num)) >= 1.0e9
      ? (Math.abs(Number(num)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(num)) >= 1.0e6
      ? (Math.abs(Number(num)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(num)) >= 1.0e3
      ? (Math.abs(Number(num)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(num));
  };

  const handlePostClick = () => {
    console.log("blog id", blog.id);
    if (isAdmin) {
      dispatch(requestAdminRouteAction("editing"));
    }
    dispatch(requestActiveBlogAction(blog.id));
  };

  const handleDelete = () => {
    fetch(`${server}/post/${blog.id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => dispatch(requestBlogsAction()))
      .catch((err) => console.lof(err));
  };

  // useEffect(() => {
  //   console.log(blog.titleImage);
  // }, []);

  return (
    <Link
      to={!isAdmin && `/post/${blog.id}`}
      className="post-link"
      onClick={!isAdmin && handlePostClick}
    >
      <div className="post-thumbnail-container">
        <div className="post-image-container">
          <img src={`data:image/webp;base64,${blog.titleImage}`} alt="post" />
        </div>
        <div className="post-text-container">
          {/* <div className="post-category-container">
            <p className="blog-category">{blog.category}</p>
            <p className="post-owner">{blog.createdByUserName}</p>
          </div> */}
          <h3>{blog.title.slice(0, 48)}....</h3>
          {/* <p className="post-desc">{blog.description.slice(0, 80)}...</p> */}
          {!isAdmin && (
            <div className="post-view-container">
              {/* <p>{getNumber(blog.viewsCount)} views</p> */}
              <p>{difference}</p>
            </div>
          )}
          {isAdmin && (
            <div className="post-buttons">
              <button
                className="btn btn-primary custom-button"
                onClick={handlePostClick}
              >
                Edit
              </button>
              <button
                className="btn btn-danger custom-button"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default PostThumbnail;
