import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { requestNavAction } from "../../Actions/actions";
import {
  requestAdminAction,
  requestAdminRouteAction,
  requestRoute,
} from "../../Actions/actions.js";
import Logo from "../Logo/Logo";
import "./navbar.scss";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentRoute } = useSelector((state) => state.setRoute);
  const { isOpen } = useSelector((state) => state.requestNavReducer);
  const { isAdmin } = useSelector((state) => state.requestAdminReducer);
  const { route } = useSelector((state) => state.requestAdminRouteReducer);
  const handleNavbar = () => {
    dispatch(requestNavAction());
  };
  //navbar scroll changeBackground function
  // const changeBackground = () => {
  // //   if (window.scrollY >= 66) {
  // //     setNavbar(true);
  // //   } else {
  // //     setNavbar(false);
  // //   }
  // // };
  const handleNavigation = (route, itemName) => {
    if (isOpen) {
      handleNavbar();
    }
    if (location.pathname !== "/") {
      navigate("/");
    }
    dispatch(requestRoute(route));
    dispatch({ type: "SET_ACTIVE_NAV_ITEM", payload: itemName }); // Update active item immediately

    // Add the code for <span data-bs-target="#navbarNav" data-bs-toggle="collapse"> here
    if (isOpen) {
      const navbarNav = document.getElementById("navbarNav");
      navbarNav.classList.remove("show"); // Hide the collapsed navbar
    }
  };

  const handleLogout = () => {
    dispatch(requestAdminAction());
    navigate("/");
  };
  const handleAdminRoute = (route) => {
    console.log(`this is the route: ${route}`);
    if (isOpen) {
      handleNavbar();
    }
    if (isOpen) {
      const navbarNav = document.getElementById("navbarNav");
      navbarNav.classList.remove("show"); // Hide the collapsed navbar
    }
    dispatch(requestAdminRouteAction(route));
  };

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 66) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    changeBackground();

    const handleScroll = () => {
      changeBackground();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return isAdmin ? (
    <nav
      className={
        navbar
          ? "container-fluid navbar bg-body-tertiary fixed-top navbar-expand-lg navbar-background"
          : "container-fluid navbar fixed-top bg-body-tertiary navbar-expand-lg"
      }
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <Logo />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li
              className={`nav-item ${route === "blogs" ? "active" : ""}`}
              onClick={() => handleAdminRoute("blogs")}
            >
              Blogs
            </li>
            <li
              className={`nav-item ${route === "editing" ? "active" : ""}`}
              onClick={() => handleAdminRoute("editing")}
            >
              Publish
            </li>
            <li
              className={`nav-item ${
                currentRoute === "contactus" ? "active" : ""
              }`}
              onClick={() => handleLogout()}
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </nav>
  ) : (
    <nav
      className={
        navbar
          ? "container-fluid navbar bg-body-tertiary fixed-top navbar-expand-lg navbar-background"
          : "container-fluid navbar fixed-top bg-body-tertiary navbar-expand-lg"
      }
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <Logo />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li
              className={`nav-item ${currentRoute === "home" ? "active" : ""}`}
              onClick={() => handleNavigation("home")}
            >
              Home
            </li>
            <li
              className={`nav-item ${currentRoute === "info" ? "active" : ""}`}
              onClick={() => handleNavigation("info")}
            >
              About Us
            </li>
            <li
              className={`nav-item ${
                currentRoute === "contactus" ? "active" : ""
              }`}
              onClick={() => handleNavigation("contactus")}
            >
              Contact Us
            </li>
            <li
              className={`nav-item ${
                currentRoute === "policy" ? "active" : ""
              }`}
              onClick={() => handleNavigation("policy")}
            >
              Policy
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
