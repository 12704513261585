// import { blogs } from "../../../server/controllers/blogsData.js";
import {
  CHANGE_ADMIN_ROUTE_REQUEST,
  CHANGE_ADMIN_STATUS_REQUEST,
  CHANGE_ROUTE_REQUEST,
  REQUEST_ACTIVE_BLOG_FAILED,
  REQUEST_ACTIVE_BLOG_PENDING,
  REQUEST_ACTIVE_BLOG_SUCCESS,
  REQUEST_BLOGS_FAILED,
  REQUEST_BLOGS_PENDING,
  REQUEST_BLOGS_SUCCESS,
  REQUEST_EDIT_BLOG_FAILED,
  REQUEST_EDIT_BLOG_PENDING,
  REQUEST_EDIT_BLOG_SUCCESS,
  REQUEST_NAVBAR_STATUS,
  REQUEST_TOTAL_BLOGS_FAILED,
  REQUEST_TOTAL_BLOGS_PENDING,
  REQUEST_TOTAL_BLOGS_SUCCESS,
  RESET_EDIT_BLOG,
} from "../Constants/constants.js";

// ? Identifying Routes to track which route user is in currently

const initialRoute = {
  currentRoute: "home",
};

export const setRoute = (state = initialRoute, actions = {}) => {
  switch (actions.type) {
    case CHANGE_ROUTE_REQUEST:
      return { ...state, currentRoute: actions.payload };
    default:
      return state;
  }
};

// ? Getting all blogs to show in thumbnail

const initialStateBlogs = {
  isPending: false,
  blogs: [],
  error: "",
};

export const requestBlogsReducer = (state = initialStateBlogs, action = {}) => {
  switch (action.type) {
    case REQUEST_BLOGS_PENDING:
      return { ...state, isPending: true };
    case REQUEST_BLOGS_SUCCESS:
      return {
        ...state,
        isPending: false,
        blogs: [...state.blogs, ...action.payload],
      };
    case REQUEST_BLOGS_FAILED:
      return { ...state, isPending: false, error: action.payload };
    default:
      return state;
  }
};

// ? Getting details of the active blog that user is seeing currently

const initialActiveBlogState = {
  isPending: false,
  blog: [],
  error: "",
  isActive: false,
};

export const requestActiveBlogReducer = (
  state = initialActiveBlogState,
  action = {}
) => {
  switch (action.type) {
    case REQUEST_ACTIVE_BLOG_PENDING:
      return Object.assign({}, state, { isPending: true });
    case REQUEST_ACTIVE_BLOG_SUCCESS:
      return Object.assign({}, state, {
        blog: action.payload,
        isPending: false,
        isActive: true,
      });
    case REQUEST_ACTIVE_BLOG_FAILED:
      return Object.assign({}, state, {
        error: action.payload,
        isPending: false,
        isActive: true,
      });
    case RESET_EDIT_BLOG:
      return initialActiveBlogState;
    default:
      return state;
  }
};

// ? Getting confirmation if user is admin or not

const initialStateAdmin = {
  isAdmin: false,
};

export const requestAdminReducer = (
  state = initialStateAdmin,
  actions = {}
) => {
  switch (actions.type) {
    case CHANGE_ADMIN_STATUS_REQUEST:
      return { ...state, isAdmin: !state.isAdmin };
    default:
      return state;
  }
};

// ? Setting Admin Route

const initialAdminRoute = {
  route: "blogs",
};

export const requestAdminRouteReducer = (
  state = initialAdminRoute,
  actions = {}
) => {
  switch (actions.type) {
    case CHANGE_ADMIN_ROUTE_REQUEST:
      return { ...state, route: actions.payload };
    default:
      return state;
  }
};

// ? Active blog for editing

const initialEditBlog = {
  isPending: false,
  blog: [],
  error: "",
};

export const requestEditBlog = (state = initialEditBlog, actions = {}) => {
  switch (actions.type) {
    case REQUEST_EDIT_BLOG_PENDING:
      return Object.assign({}, state, { isPending: true });
    case REQUEST_EDIT_BLOG_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        blog: actions.payload,
      });
    case REQUEST_EDIT_BLOG_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: actions.payload,
      });
    case RESET_EDIT_BLOG:
      return state;
    default:
      return state;
  }
};

// ? Navigation bar

const initialNavState = {
  isOpen: false,
};

export const requestNavReducer = (state = initialNavState, actions = {}) => {
  switch (actions.type) {
    case REQUEST_NAVBAR_STATUS:
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
};
