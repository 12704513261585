export const REQUEST_BLOGS_PENDING = "REQUEST_BLOGS_PENDING";
export const REQUEST_BLOGS_SUCCESS = "REQUEST_BLOGS_SUCCESS";
export const REQUEST_BLOGS_FAILED = "REQUEST_BLOGS_FAILED";
export const CHANGE_ROUTE_REQUEST = "CHANGE_ROUTE_REQUEST";
export const REQUEST_ACTIVE_BLOG_PENDING = "REQUEST_ACTIVE_BLOG_PENDING";
export const REQUEST_ACTIVE_BLOG_SUCCESS = "REQUEST_ACTIVE_BLOG_SUCCESS";
export const REQUEST_ACTIVE_BLOG_FAILED = "REQUEST_ACTIVE_BLOG_FAILED";
export const CHANGE_ADMIN_STATUS_REQUEST = "REQUEST_ADMIN_STATUS_REQUEST";
export const CHANGE_ADMIN_ROUTE_REQUEST = "CHANGE_ADMIN_ROUTE_REQUEST";
export const REQUEST_EDIT_BLOG_PENDING = "REQUEST_EDIT_BLOG_PENDING";
export const REQUEST_EDIT_BLOG_SUCCESS = "REQUEST_EDIT_BLOG_SUCCESS";
export const REQUEST_EDIT_BLOG_FAILED = "REQUEST_EDIT_BLOG_FAILED";
export const RESET_EDIT_BLOG = "RESET_EDIT_BLOG";
export const REQUEST_NAVBAR_STATUS = "REQUEST_NAVBAR_STATUS";
