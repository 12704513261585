import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import "./aboutUs.scss";

const AboutUs = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  });
  return (
    <div className="about-us-container">
      <div className="ads-container">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="fluid"
          data-ad-layout-key="-fb+5w+4e-db+86"
          data-ad-client="ca-pub-9847191225953947"
          data-ad-slot="5632151696"
        ></ins>
      </div>
      <h2>About Us:</h2>
      <p>
        Welcome to Awwrites, your ultimate destination for insightful and
        engaging blog content. We are passionate about sharing stories, ideas,
        and knowledge through the art of blogging. Whether you're an avid
        reader, a curious learner, or a fellow blogger, we're thrilled to have
        you as part of our community.
      </p>
      <p>
        At Awwrites, we believe in the power of words to inspire, educate, and
        connect people from all walks of life. Our team of talented writers and
        contributors are dedicated to crafting thought-provoking and informative
        articles across a wide range of topics. From lifestyle and travel to
        technology and personal development, we strive to cover subjects that
        resonate with our diverse audience.
      </p>
      <p>
        What sets us apart is our commitment to quality. We understand that your
        time is valuable, which is why we go the extra mile to deliver
        well-researched, well-written, and engaging content that keeps you
        coming back for more. We believe in delivering value with every article
        we publish, aiming to provide you with valuable insights, practical
        tips, and inspiration that enrich your life in meaningful ways.
      </p>
      <p>
        But Awwrites is more than just a collection of blog posts. It's a
        community where like-minded individuals can connect, engage, and share
        their thoughts. We encourage open dialogue, fostering a welcoming
        environment where everyone's voice is heard and respected. We value your
        feedback and ideas, as they play a crucial role in shaping the content
        we produce.
      </p>
      <p>
        Whether you're seeking guidance, seeking inspiration, or simply seeking
        to broaden your horizons, Awwrites is here to accompany you on your
        journey. We invite you to explore our articles, dive into the comment
        sections, and become an active participant in our thriving community.
        Together, we can learn, grow, and make a positive impact in our lives
        and the lives of others.
      </p>
      <p>
        Thank you for joining us on this exciting adventure. We look forward to
        connecting with you and being a trusted source of inspiration and
        knowledge in your blogging journey.
      </p>
      <div className="ads-container">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="fluid"
          data-ad-layout-key="-fb+5w+4e-db+86"
          data-ad-client="ca-pub-9847191225953947"
          data-ad-slot="5632151696"
        ></ins>
      </div>
    </div>
  );
};

export default AboutUs;
