import Stack from "@mui/material/Stack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestAdminAction, requestRoute } from "../../Actions/actions";
import logo from "../../Assets/logo.png";
import "./Logo.scss";
const Logo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAdmin } = useSelector((state) => state.requestAdminReducer);

  const handleRouteChange = () => {
    dispatch(requestRoute("home"));
    if (isAdmin) dispatch(requestAdminAction());
    navigate("/");
  };
  return (
    <Stack
      id="logo-container"
      direction="row"
      spacing={1}
      onClick={handleRouteChange}
    >
      <img className="logo-image" src={logo} alt="logo" />
      {/* <h1 className="logo-text">Brand Name</h1> */}
    </Stack>
  );
};

export default Logo;
