import React, { useState } from "react";
import { useSelector } from "react-redux";
import AdminLogin from "../Admin-login/adminLogin";
import AdminBlogs from "../AdminBlogs/adminBlogs";
import EditingPanel from "../EditPanel/editingPanel";
// import Editor from "../Editor/editor";
import "./profile.scss";

// const initialState = {
//   title: "",
//   titleImage: null,
//   content: null,
// };

const Profile = () => {
  // const [selectedFile, setSelectedFile] = useState();
  // const [data, setData] = useState(initialState);
  // const [errorMsg, setErrorMsg] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState("");
  const { isAdmin } = useSelector((state) => state.requestAdminReducer);
  const { route } = useSelector((state) => state.requestAdminRouteReducer);
  // const [adminRoute, setAdminRoute] = useState("blogs");
  const handleResonse = (data) => {
    setOpen(true);
    setResponse(data);
  };
  const onRouteChage = () => {
    switch (route) {
      case "blogs":
        return <AdminBlogs open={open} setOpen={setOpen} response={response} />;
      case "editing":
        return <EditingPanel setResponse={handleResonse} />;
      default:
        return <AdminBlogs />;
    }
  };

  // const validateSelectedFile = async (selectedFile) => {
  //   const MAX_FILE_SIZE = 100; // 100KB

  //   const fileSizeKiloBytes = selectedFile.size / 1024;

  //   if (fileSizeKiloBytes > MAX_FILE_SIZE) {
  //     setErrorMsg("File size is greater than maximum limit");
  //     setIsSuccess(false);
  //   } else {
  //     setErrorMsg("");
  //     setIsSuccess(true);
  //     const base64 = await convertToBase64(selectedFile);
  //     // console.log(base64);
  //     setData({ ...data, titleImage: base64 });
  //   }
  // };

  // const handleTitleChange = (event) => {
  //   setData({ ...data, title: event.target.value });
  //   // console.log(data);
  // };

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];

  //   if (!selectedFile) {
  //     setErrorMsg("Please choose a file");
  //     setIsSuccess(false);
  //     return;
  //   }
  //   validateSelectedFile(selectedFile);
  //   // console.log(selectedFile);
  // };

  // const getEditorValue = (value) => {
  //   setValue(value);
  //   setData({ ...data, content: value });
  // };

  // const convertToBase64 = async (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   fetch("http://localhost:5000/generate", {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => console.log(data))
  //     .catch((err) => console.log(err));
  //   // console.log("profile component", data);
  // };

  return !isAdmin ? (
    <AdminLogin />
  ) : (
    // <>
    //   <form className="editing-form-container" onSubmit={handleSubmit}>
    //     <div className="blog-input-container">
    //       <label htmlFor="top-title">Enter the heading for your blog:</label>
    //       <input
    //         type="text"
    //         name="title"
    //         placeholder="Enter your title here"
    //         onChange={handleTitleChange}
    //         required
    //       />
    //     </div>

    //     <div className="blog-input-container">
    //       <label htmlFor="title-image">
    //         Choose the image file for your Title image and thumbnail:{" "}
    //         <span className="note-warning">
    //           (Please note: Image files should only have .webp extension and
    //           file size shouldn't exceed 100Kb )
    //         </span>
    //       </label>
    //       <input
    //         title="Please enter only webp images only"
    //         type="file"
    //         name="titleImage"
    //         accept="image/webp"
    //         onChange={handleFileChange}
    //         required
    //       />
    //       {isSuccess ? (
    //         <p className="success-message">Validation successful</p>
    //       ) : null}
    //       <p className="error-message">{errorMsg}</p>
    //     </div>

    //     <div className="blog-input-container">
    //       <label>Write your blog content here:</label>
    //       <Editor onChange={getEditorValue} />
    //     </div>

    //     <div className="blog-btn-group">
    //       <button
    //         type="submit"
    //         className="btn btn-primary"
    //         disabled={!isSuccess}
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   </form>
    // </>
    // <AdminRoute.Provider value={setRoute}>{onRouteChage()}</AdminRoute.Provider>
    onRouteChage()
  );
};

export default Profile;
