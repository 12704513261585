import React, { useState } from "react";
// import PostThumbnail from "../PostThumbnail/postThumbnail";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import PostsContainer from "../Posts-container/PostsContainer";
import "./adminblogs.scss";

const AdminBlogs = ({ response, open, setOpen }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  let myAlert;

  if (response.length) {
    if (response === "Success") {
      myAlert = (
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Success! Your blog is updated successfully.
        </Alert>
      );
    } else {
      myAlert = (
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Error! An error occured. Please try again.
        </Alert>
      );
    }
  }

  return (
    <div className="admin-blogs-container">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        {myAlert}
      </Snackbar>
      <PostsContainer />
    </div>
  );
};

export default AdminBlogs;
