import React, { useEffect, useRef, useState } from "react";
// import { users } from "../../GetApis/users";
// import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  requestBlogsAction,
  requestTotalBlogsAction,
} from "../../Actions/actions.js";
import LoadingIcon from "../Loading-Spinner/loadingIcon.jsx";
import PostThumbnail from "../PostThumbnail/postThumbnail";
import "./posts-container.scss";

const PostsContainer = () => {
  const dispatch = useDispatch();
  const blogsLengthRef = useRef(0);
  const { isPending, blogs, error } = useSelector(
    (state) => state.requestBlogsReducer
  );
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const onRequestBlogs = (input = 0) => {
    // requestUsersAction(dispatch);
    dispatch(requestBlogsAction(input));
  };
  const server = process.env.REACT_APP_PRODUCTION_SERVER;

  //To prevent the handleInfiniteScroll function from firing multiple times when the scroll position reaches the desired point, you can introduce a debounce mechanism. Debouncing will delay the execution of the function and ensure that it only fires once even if multiple scroll events occur within a short period.

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const handleInfiniteScroll = debounce(() => {
    if (
      !isLoading &&
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
    ) {
      setIsLoading(true);
      onRequestBlogs(blogsLengthRef.current);
    }
  }, 200);

  const getTotalBlogs = () => {
    fetch(`${server}/numberOfBlogs`)
      .then((res) => res.json())
      .then((data) => setTotalBlogs(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTotalBlogs();
    if (!blogs.length) {
      onRequestBlogs();
    }
    // console.log(error);
    // console.log("blogs", blogs.length);
  }, []);

  useEffect(() => {
    blogsLengthRef.current = blogs.length;
    setIsLoading(false); // Reset isLoading when blogs update
  }, [blogs]);

  useEffect(() => {
    // dispatch(requestTotalBlogsAction());
    // console.log(totalBlogs);
    if (blogs.length < totalBlogs && blogs.length > 0) {
      window.addEventListener("scroll", handleInfiniteScroll);
      return () => window.removeEventListener("scroll", handleInfiniteScroll);
    }
  }, [isLoading, totalBlogs, blogs]);

  return (
    <section id="posts-container">
      {blogs.map((blog) => {
        return <PostThumbnail blog={blog} key={blog.id} />;
      })}
      {isLoading && (
        <div className="align-loading">
          <LoadingIcon />
        </div>
      )}
    </section>
  );
};

export default PostsContainer;
