import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
// import quill from "quill";
import React, { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.scss";

const Editor = ({ onChange, blogValue }) => {
  const [value, setValue] = useState("");
  const handleChange = (html) => {
    setValue(html);

    console.log("editor component", value);
    onChange(html);
  };

  hljs.configure({
    languages: ["javascript", "ruby", "python", "rust"],
  });

  const modules = useMemo(
    () => ({
      syntax: {
        highlight: (text) => hljs.highlightAuto(text).value,
      },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "video", "code-block"],
          ["image"],
          [{ align: [] }],
          ["clean"],
        ],
        handlers: {
          image: function () {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/png, image/jpeg, image/webp");
            input.addEventListener("change", () => {
              const file = input.files[0];
              const MAX_FILE_SIZE = 100;
              const fileSize = file.size / 1024;
              if (
                (file.type === "image/webp" ||
                  file.type === "image/png" ||
                  file.type === "image/jpeg") &&
                fileSize <= MAX_FILE_SIZE
              ) {
                const reader = new FileReader();
                reader.onload = () => {
                  const range = this.quill.getSelection(true);
                  this.quill.insertEmbed(range.index, "image", reader.result);
                };
                reader.readAsDataURL(file);
              } else {
                alert("Please select a valid image file within 100KB");
              }
            });
            input.click();
          },
        },
        image: {
          toolbar: true,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = [
    "header",
    "color",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "code-block",
    "link",
    "image",
    "video",
    "align",
    "background",
  ];

  useEffect(() => {
    // console.log(value);
    if (blogValue) {
      console.log("Blog Value", blogValue);
      setValue(blogValue);
    }
  }, [blogValue]);

  return (
    <div>
      <ReactQuill
        className="editor-container"
        dangerouslySetInnerHTML={{ __html: blogValue }}
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder="Enter your text"
      />
    </div>
  );
};

export default Editor;
