import React from "react";
import "./page-not-found.scss";

const PageNotFound = () => {
  return (
    <section id="page-not-found">
      <h1>404: Page Not Found</h1>
      <p>Sorry! There is nothing here... </p>
    </section>
  );
};

export default PageNotFound;
