import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import React, { lazy, Suspense } from "react";
import React, { useEffect } from "react";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
// import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import Navbar from "../../components/Navbar/Navbar";
import TopBar from "../../components/Topbar/Topbar";
// import Forum from "../routes/Forum/forum";
import { Navigate, Route, Routes } from "react-router-dom";
// import BlogDetails from "../components/Blog-Details/blofDetails";
// import Home from "../../routes/Home/home";
import BlogDetails from "../../components/Blog-Details/blogDetails";
import Profile from "../../components/Profile/profile";
// import RouteChangeTracker from "../../components/Route-Change-GA/routeChange";
import { useLocation } from "react-router-dom";
import PageNotFound from "../../components/404/page-not-found";
import AboutUs from "../../routes/About-us/aboutUs";
import GuestUser from "../Guest/guestUser";
import "./App.scss";

// const Forum = lazy(() => import("../../routes/Forum/forum"));
// const BlogDeatils = lazy(() =>
//   import("../../components/Blog-Details/blogDetails.jsx")
// );

const App = () => {
  const location = useLocation();
  // const dispatch = useDispatch();
  // const { currentRoute } = useSelector((state) => state.setRoute);
  // const onRouteChange = () => {
  //   switch (currentRoute) {
  //     case "home":
  //       return <Home />;
  //     case "forum":
  //       return (
  //         <Suspense>
  //           <Forum />
  //         </Suspense>
  //       );
  //     default:
  //       return <Home />;
  //   }
  // };
  // useEffect(() => {
  //   onRouteChange();
  // });
  useEffect(() => {
    ReactGA.initialize("G-B7H1WTQ3DP");
    const SendAnalytics = () => {
      ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Root Page",
      });
    };
    SendAnalytics();
  }, []);
  return (
    <div className="App">
      <TopBar />
      <Routes>
        {/* Route with topbar */}
        <Route
          path="/"
          element={
            <>
              {/* <TopBar /> */}
              <GuestUser />
            </>
          }
        />
        <Route path="post/:id" element={<BlogDetails />} />

        {/* Route without topbar */}
        <Route path="profile/admin" element={<Profile />} />
        <Route path="404" element={<PageNotFound />} />

        {/* Redirect to home page if user enters an invalid URL */}
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      {/* <Routes>
        <Route path="/" element={<GuestUser />} />
        <Route path="/post/:id" element={<BlogDetails />} />
        <Route path="/profile/admin" element={<Profile />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes> */}
      {/* <div className="main-container">
        <Navbar />
        {onRouteChange()}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/forum"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Forum />
              </Suspense>
            }
          />
          <Route
            path="/post"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <BlogDeatils />
              </Suspense>
            }
          />
        </Routes>
      </div> */}
    </div>
  );
};

export default App;
