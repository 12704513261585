import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestActiveBlogAction,
  requestAdminRouteAction,
  requestEditBlogAction,
} from "../../Actions/actions";
// import { addNewBlogToSitemap } from "../../newSitemap";
import AdminLogin from "../Admin-login/adminLogin";
import Editor from "../Editor/editor";
import "./editingPanel.scss";

const initialState = {
  title: "",
  titleImage: null,
  content: null,
  description: "",
  keywords: [],
};

const EditingPanel = ({ setResponse }) => {
  // const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();
  const [data, setData] = useState(initialState);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const { isAdmin } = useSelector((state) => state.requestAdminReducer);
  const { isPending, blog, error, isActive } = useSelector(
    (state) => state.requestActiveBlogReducer
  );
  const server = process.env.REACT_APP_PRODUCTION_SERVER;

  const validateSelectedFile = async (selectedFile) => {
    const MAX_FILE_SIZE = 100; // 100KB

    const fileSizeKiloBytes = selectedFile.size / 1024;

    // if (isActive) {
    //   setData({
    //     ...data,
    //     titleImage: `data:image/webp;base64,${blog.titleImage}`,
    //   });
    // } else {
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
    } else {
      setErrorMsg("");
      setIsSuccess(true);
      const base64 = await convertToBase64(selectedFile);
      // console.log(base64);
      setData({ ...data, titleImage: base64 });
    }
    // }
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    console.log("data updated", data);
  };

  const handleKeywordsChange = (event) => {
    const string = event.target.value;
    const array = string.split(", ");
    setData({ ...data, keywords: array });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      setErrorMsg("Please choose a file");
      setIsSuccess(false);
      return;
    }
    validateSelectedFile(selectedFile);
    // console.log(selectedFile);
  };

  const getEditorValue = (value) => {
    setValue(value);
    setData({ ...data, content: value });
  };

  const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Data sending to server", data);
    fetch(`${server}/generate`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        // addNewBlogToSitemap(data);
        setData(initialState);
        dispatch(requestAdminRouteAction("blogs"));
        // setResponse("Success");
      })
      .catch((err) => setResponse("Error"));
    // console.log("profile component", data);
  };

  // const b64toBlob = (b64Data) => {
  //   const sliceSize = 512;
  //   const byteCharacters = atob(b64Data);
  //   const byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   return new Blob(byteArrays, { type: "image/jpeg" });
  // };

  const handleUpdate = (e) => {
    e.preventDefault();
    // console.log("Updated data", data);
    setData(initialState);
    dispatch(requestAdminRouteAction("blogs"));
    fetch(`${server}/post/${blog.id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setResponse("Success");

        dispatch(requestActiveBlogAction());
      })
      .catch((err) => setResponse("Error"));
  };

  const handleCancel = () => {
    setData(initialState);
    dispatch(requestActiveBlogAction());
    dispatch(requestAdminRouteAction("blogs"));
  };

  // useEffect(() => {
  //   if (blog.titleImage) {
  //     const blob = b64toBlob(`data:image/png;base64,${blog.titleImage}`);
  //     const url = URL.createObjectURL(blob);
  //     setImageUrl(url);
  //   }
  // }, [blog.titleImage]);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setData({
          title: blog.title,
          titleImage: `data:image/webp;base64,${blog.titleImage}`,
          content: blog.content,
          description: blog.description,
          keywords: blog.keywords,
        });

        console.log("data", data);
      }, 500);
    } else {
      return;
    }
  }, [isActive, blog]);

  return !isAdmin ? (
    <AdminLogin />
  ) : (
    <>
      <form className="editing-form-container" onSubmit={handleSubmit}>
        <div className="blog-input-container">
          <label htmlFor="top-title">Enter Title for your blog:</label>
          <input
            type="text"
            name="title"
            placeholder="Enter your title here"
            onChange={handleTitleChange}
            value={data.title}
            required
          />
        </div>
        <div className="blog-input-container">
          <label htmlFor="top-title">
            Enter a short description for your blog:
          </label>
          <input
            type="text"
            name="description"
            placeholder="Enter your description here"
            onChange={handleTitleChange}
            value={data.description}
            required
          />
        </div>
        <div className="blog-input-container">
          <label htmlFor="top-title">Enter keywords for your blog:</label>
          <input
            type="text"
            name="keywords"
            placeholder="Enter your keywords here"
            onChange={handleKeywordsChange}
            value={data.keywords.length ? data.keywords.join(", ") : ""}
            required
          />
        </div>

        <div className="blog-input-container">
          <label htmlFor="title-image">
            Choose the image file for your Title image and thumbnail:{" "}
            <span className="note-warning">
              (Please note: Image files should only have .webp extension and
              file size shouldn't exceed 100Kb )
            </span>
          </label>
          <input
            title="Please enter only webp images only"
            type="file"
            name="titleImage"
            accept="image/webp"
            onChange={handleFileChange}
            // value={blog.length !== 0 && imageUrl}
            required
          />
          {isSuccess ? (
            <p className="success-message">Validation successful</p>
          ) : null}
          <p className="error-message">{errorMsg}</p>
          {data.titleImage && (
            <div className="image-preview">
              <img src={data.titleImage} alt="title-pic" />
            </div>
          )}
        </div>

        <div className="blog-input-container">
          <label>Write your blog content here:</label>
          <Editor
            onChange={getEditorValue}
            blogValue={blog.length !== 0 && blog.content}
          />
        </div>

        <div className="blog-btn-group">
          {!isActive ? (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!isSuccess}
            >
              Submit
            </button>
          ) : (
            <div>
              <button
                type="button"
                className="btn btn-primary margin-right"
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default EditingPanel;
