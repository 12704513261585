import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestAdminAction,
  requestAdminRouteAction,
} from "../../Actions/actions";
import Logo from "../Logo/Logo";
import Navbar from "../Nvabar-boot/navbar";
import SearchField from "../SearchField/SearchField";
import "./Topbar.scss";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[700]),
  padding: "0.3rem 2rem",
  alignItems: "center",
  backgroundColor: purple[700],
  "&:hover": {
    backgroundColor: purple[900],
  },
}));

const TopBar = () => {
  const { isAdmin } = useSelector((state) => state.requestAdminReducer);
  const { isOpen } = useSelector((state) => state.requestNavReducer);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(requestAdminAction());
  };
  const handleAdminRoute = (route) => {
    console.log(`this is the route: ${route}`);
    dispatch(requestAdminRouteAction(route));
  };

  const handleScroll = () => {
    if (isOpen) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "auto";
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, [isOpen]);
  return (
    <header className="topbar-container">
      {/* <Logo /> */}
      <div className={isOpen ? "back-drop" : "display-none"}></div>
      <>
        {/* <button className="mobile-search-button">
            <SearchIcon />
          </button> */}
        {/* <SearchField /> */}
        {/* <ColorButton id="color-button" variant="contained">
            Subscribe
          </ColorButton> */}
        <Navbar />
      </>
    </header>
  );
};

export default TopBar;
