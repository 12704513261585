import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import "./loadingicon.scss";

const LoadingIcon = () => {
  return (
    <Box
      className="loading-icon"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingIcon;
