import { combineReducers, configureStore } from "@reduxjs/toolkit";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createLogger } from "redux-logger";
import {
  requestActiveBlogReducer,
  requestAdminReducer,
  requestAdminRouteReducer,
  requestBlogsReducer,
  requestNavReducer,
  setRoute,
} from "./Reducers/reducers";
import App from "./containers/App/App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const logger = createLogger();
const rootReducer = combineReducers({
  setRoute,
  requestBlogsReducer,
  requestActiveBlogReducer,
  requestAdminReducer,
  requestAdminRouteReducer,
  requestNavReducer,
});
const store = configureStore({
  reducer: rootReducer, // We will put rootReducer here
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(), // (mw1, mw2, ...) for multiple middlewares and add "logger" inside concat brackets
});

let root;
const domNode = document.getElementById("root");
root = domNode.hasChildNodes() ? hydrateRoot(domNode) : createRoot(domNode);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
