import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { requestAdminAction } from "../../Actions/actions";
import "./adminLogin.scss";

const initialState = {
  id: "",
  password: "",
};

const AdminLogin = () => {
  const [formField, setFormField] = useState(initialState);
  const server = process.env.REACT_APP_PRODUCTION_SERVER;
  const dispatch = useDispatch();
  const { id, password } = formField;
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormField({ ...formField, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formField);
    const response = await fetch(
      `${server}/admin`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formField),
      }
    ).then((res) => res.json());
    // console.log(response);
    if (response === "verified") {
      dispatch(requestAdminAction());
      setFormField(initialState);
    } else {
      alert(
        "Incorrect userid or password. Please insert correct credentials to continue"
      );
      setFormField(initialState);
    }
  };
  return (
    <div className="login-form">
      <form className="login-container">
        <label>User Id</label>
        <input
          className="login-input"
          type="text"
          name="id"
          value={id}
          placeholder="Enter User Id"
          onChange={handleChange}
        />
        <label>Password</label>
        <input
          className="login-input"
          type="password"
          name="password"
          placeholder="Enter password"
          value={password}
          onChange={handleChange}
        />
        <button
          onClick={handleSubmit}
          className="login-form-submit"
          type="submit"
        >
          LogIn
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
